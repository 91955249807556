<template>
  <v-row>
    <v-col cols="12" md="12">
      <table style="width: 100%">
        <tr>
          <th style="width: 30%">Dates:</th>
          <td>{{ this.item.item.vacationDates.join(", ") }}</td>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  Name: "exandInfoVacation",
  props: ["item"],
};
</script>

<style>
th {
  text-align: left;
}
</style>