<template>
  <span class="font-weight-medium">No extra information</span>
</template>

<script>
export default {
  Name: "exandInfoNormal",
};
</script>

<style>
</style>