<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
      />
      <v-spacer class="col-5" />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="timeReports"
      :search="search"
      sort-by="user"
      group-by="month"
      group-desc
      show-expand
      :expanded.sync="expanded"
    >
      <template v-slot:item.reportHours="{ item }">
        <v-chip :color="getColors(item.month, item.reportHours)">
          {{ item.reportHours }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <expand-info-vacation
            :item="{ item }"
            v-show="item.type === 'Vacation'"
          />
          <expand-info-sickness
            :item="{ item }"
            v-show="item.type === 'Sickness'"
          />
          <expand-info-parental
            :item="{ item }"
            v-show="item.type === 'Parental leave'"
          />
          <expand-info-child-care
            :item="{ item }"
            v-show="item.type === 'Care of child (VAB)'"
          />
          <expand-info-normal v-show="item.type === 'Normal'" />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import expandInfoVacation from "@/components/timeReport/expandInfoVacation.vue";
import expandInfoSickness from "@/components/timeReport/expandInfoSickness.vue";
import expandInfoParental from "@/components/timeReport/expandInfoParental.vue";
import expandInfoChildCare from "@/components/timeReport/expandInfoChildCare.vue";
import expandInfoNormal from "@/components/timeReport/expandInfoNormal.vue";
import { mapGetters } from "vuex";
export default {
  Name: "AdminTime",
  components: {
    expandInfoVacation,
    expandInfoSickness,
    expandInfoParental,
    expandInfoChildCare,
    expandInfoNormal,
  },
  data: () => ({
    errors: [],
    expanded: [],
    headers: [
      {
        text: "Month",
        value: "month",
        align: "right",
      },
      {
        text: "User",
        value: "user.data.name",
      },
      { text: "Type", value: "type" },
      {
        text: "Project",
        value: "project.data.name",
      },
      {
        text: "Reported hours",
        value: "hours",
        align: "right",
        filterable: false,
        groupable: false,
      },
    ],
    search: "",
  }),
  computed: {
    ...mapGetters({
      timeReports: "timeReports/timeReports",
    }),
  },
  methods: {
    getColors(month, hours) {
      let normalTime = this.getNormalWorkTime();
      if (hours > normalTime) {
        return "cancel";
      } else if (hours < normalTime) {
        return "primary";
      }
    },
    getNormalWorkTime() {
      return 168;
    },
  },
};
</script>
