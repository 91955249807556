<template>
  <v-row>
    <!-- <v-col cols="12" md="12">
      <p class="text-subtitle-2">Care of Child days</p>
      <v-divider></v-divider>
    </v-col> -->
    <v-col cols="12" md="12">
      <table style="width: 100%">
        <tr>
          <th>Dates</th>
          <th>Hours</th>
        </tr>
        <tr v-for="(vabItem, index) in this.item.item.vabItems" :key="index">
          <td style="width: 30%">{{ vabItem.date }}</td>
          <td>{{ vabItem.hours }}</td>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  Name: "exandInfoChildCare",
  props: ["item"],
};
</script>

<style>
th {
  text-align: left;
}
</style>