<template>
  <Time />
</template>

<script>
import Time from "@/components/admin/Time.vue";

export default {
  name: "TimeView",
  components: {
    Time,
  },
};
</script>
