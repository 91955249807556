<template>
  <v-row>
    <v-col>
      <table style="width: 100%">
        <tr>
          <th style="width: 30%">Dates:</th>
          <td>{{ this.item.item.parentalDays.join(", ") }}</td>
        </tr>
        <tr>
          <th style="width: 30%">Extent (%):</th>
          <td>
            {{ this.item.item.parentalLeaveExtent }}
          </td>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  Name: "exandInfoParental",
  props: ["item"],
};
</script>

<style>
</style>