<template>
  <v-row>
    <v-col cols="12" md="12">
      <table style="width: 100%">
        <tr>
          <th style="width: 30%">Range:</th>
          <td>
            {{ this.item.item.sickDays.join(", ") }}
          </td>
        </tr>
        <tr>
          <th>Sick hours first day:</th>
          <td>{{ this.item.item.sickHoursFirstDay }}</td>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  Name: "exandInfoSickness",
  props: ["item"],
};
</script>

<style>
th {
  text-align: left;
}
</style>